import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import ScrollToTop from "../components/ScrollToTop";
import sanityClient from "../client";
import BlockContent from "@sanity/block-content-to-react";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner
import { Helmet } from "react-helmet";

// Utility function to generate slug from title
const generateSlug = (title) => {
  return title.replace(/\s+/g, "-").replace(/[^\w-]+/g, "");
};

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch categories from Sanity
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "category"]{
            title
          }`
        );
        const formattedData = data.map((category, index) => ({
          id: index + 1,
          name: category.title,
          slug: generateSlug(category.title),
          image: `./images/category-${index + 1}.png`, // Placeholder images
        }));
        setCategories(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  console.log(categories);

  // Group categories into rows of 4
  const rows = categories.reduce((acc, _, i) => {
    if (i % 4 === 0) acc.push(categories.slice(i, i + 4));
    return acc;
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd]">
        <ClipLoader size={50} color={"#8b8986"} loading={loading} />
      </div>
    );
  }

  if (categories.length === 0) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd] font-gilroyBold text-2xl">
        No Categories found .
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Axillio Blog Categories - Explore Different Topics</title>
        <meta
          name="description"
          content="Explore various blog categories such as AI, Technology, and Business to stay updated with the latest trends."
        />
      </Helmet>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
        {/* navbar */}
        <div className="flex flex-row items-center justify-between px-4 md:px-16 lg:px-32 xl:px-48">
          <Link to={"/"}>
            <img
              alt="axillio-logo"
              src="./images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
        </div>
        {/* section one */}
        <div className="flex flex-col items-center justify-center px-4 pt-20 space-y-16 md:px-16 lg:px-32 xl:px-48 md:space-y-32 lg:space-y-40 md:pt-40 lg:pt-80">
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
            <h1 className="text-[#303030] text-lg md:text-xl font-semibold">
              Founder’s Strategic Sourcebook
            </h1>
            <h1 className="text-[#303030] text-4xl md:text-5xl text-center font-gilroyBold">
              Blogs
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center space-y-6">
            {rows.map((row, rowIndex) => (
              <div
                key={rowIndex}
                className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-4"
              >
                {row.map(({ id, name, image, slug }) => (
                  <Link
                    to={`/blogs/${slug}`}
                    key={id}
                    className="flex flex-col h-[19vh] md:h-[25vh] lg:h-[29vh]  px-4 pt-8 pb-12 md:pb-20  border-2 border-[#8b8986] items-center justify-between w-[24vh] md:w-[28vh] lg:w-[31vh]"
                  >
                    <img
                      alt={name}
                      src={image}
                      className="w-[4vh] md:w-[5vh] h-auto"
                    />
                    <h1 className="text-[#303030] text-lg md:text-xl lg:text-2xl font-bold font-gilroyBold text-center">
                      {name}
                    </h1>
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
        {/* footer */}
        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-32 xl:px-48">
          <div className="flex flex-col justify-between space-y-12 md:flex-row md:space-y-0">
            <div className="flex flex-col space-y-6">
              <h1 className="text-[#e6e2dd] text-lg md:text-2xl font-semibold">
                AXILLIO
              </h1>
              <div className="flex flex-col">
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  San Francisco
                </h1>
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  Delaware UAE
                </h1>
              </div>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">
                contact@axillio.com
              </h1>
            </div>
            <div className="flex flex-col space-y-4">
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Portfolio</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Services</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">About</h1>
            </div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
                <h1 className="text-[#e6e2dd] text-lg font-semibold">
                  Start a Project
                </h1>
                <GoArrowRight className="text-[#e6e2dd] text-lg md:text-xl" />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <a
                href="https://www.behance.net/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="behance"
                  src="./images/behance_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://x.com/AliMukhtar081"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="x"
                  src="./images/x_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://dribbble.com/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="football"
                  src="./images/footbal-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/axillio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="linkedin"
                  src="./images/linkedin_symbol.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.instagram.com/axillioofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="insta"
                  src="./images/insta-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.youtube.com/@Axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="youtube"
                  src="./images/youtube-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
            </div>

            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
