import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import ScrollToTop from "../../components/ScrollToTop";
import { Helmet } from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner
import LazyImage from "../../components/LazyImage";

const Athlantix = () => {
  const [loading, setLoading] = useState(true);

  // Optionally set loading to false if all images use LazyImage
  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#e6e2dd]">
        <ClipLoader size={50} color={"#8b8986"} loading={loading} />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>Athlantix - Performance Tracking for Football Athletes</title>
        <meta
          name="description"
          content="Athlantix provides advanced performance tracking and video analytics for football athletes, helping them reach their full potential."
        />

        <meta
          name="keywords"
          content="Athlantix, football performance, video analytics, football athletes, tracking, NFL, NCAA"
        />
      </Helmet>
      <ScrollToTop />
      <div className="bg-[#e6e2dd] pt-12 min-h-screen font-gilroyRegular">
        {/* navbar */}
        <div className="flex flex-row items-center justify-between px-8 md:px-16 lg:px-24 xl:px-48">
          <Link to={"/"}>
            <LazyImage
              alt="axillio-logo"
              src="/images/axillio-logo.png"
              className="w-full h-auto"
            />
          </Link>
          <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
            <h1 className="text-[#303030] text-base md:text-xl font-semibold">
              Start a Project
            </h1>
            <GoArrowRight className="text-[#303030] text-base md:text-xl" />
          </div>
        </div>
        {/* section one */}
        <div className="flex flex-col items-center justify-center pt-20 space-y-16 md:space-y-20 md:pt-40 lg:pt-80">
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
            <h1 className="text-[#303030] text-4xl md:text-6xl text-center font-gilroyBold">
              Athlantix
            </h1>
            <h1 className="text-[#303030] text-lg md:text-xl text-center font-semibold">
              "Empowering American Football Players, Coaches, and Parents"
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center w-full space-y-6">
            <LazyImage
              alt="athlantix"
              src="/images/athlantix/athlantix-1.png"
              className="w-full h-auto"
            />
          </div>

          <div className="flex flex-col items-center justify-center pt-10 space-y-4 md:space-y-16">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              The Challenge
            </h1>
            <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[60%] lg:max-w-[55vh]">
              Athlantix is a comprehensive platform designed to provide seamless
              connection and control for American football players, coaches, and
              parents.
              <br /> <br /> The platform offers in-depth stats, progress
              tracking, and personalized insights, empowering coaches with
              performance data and parents with real-time updates on their
              children’s athletic journey. Our role was to design, develop, and
              launch Athlantix, crafting every aspect of the brand's visual
              identity, digital experience, and growth strategy.
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center px-4 pt-12 space-y-8 md:space-y-16 md:px-0">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Problems
            </h1>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col items-center justify-center space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-[23vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    1.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Disconnected Communication Channels
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-[23vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    2.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Lack of Real-Time Data Access
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-[23vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    3.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Scattered Progress Tracking
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-[23vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    4.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Difficulty in Branding and User Engagement
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start space-x-0 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-[23vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    5.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Complex User Experience (UX) for Multiple User Types
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center px-8 pt-12 space-y-4 md:space-y-16 md:px-0">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              What we solved
            </h1>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-full min-h-[40vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    1.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Designed a unified dashboard system for players, coaches,
                    and parents, offering tailored views with personalized
                    stats, schedules, and notifications.
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-full min-h-[40vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    2.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Integrated performance analytics and stat-tracking features
                    for coaches, enabling them to access critical data during
                    matches and practice sessions.
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-full min-h-[40vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    3.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Developed features for milestone tracking and progress
                    reports, ensuring parents and players could monitor growth
                    trends seamlessly.
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-full min-h-[40vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    4.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Created a comprehensive design system including logo,
                    typography, and visual guidelines to establish a strong
                    brand identity.
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[40vh] py-10 px-5 space-y-8 h-full min-h-[40vh]">
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold px-2">
                    5.
                  </h1>
                  <h1 className="text-[#303030] text-lg md:text-xl text-left font-semibold">
                    Implemented role-based dashboards with intuitive UI flows,
                    ensuring all user types (Players, Coaches, Parents) had easy
                    access to their required data.{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center pt-12 space-y-4 md:space-y-16">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Key Impact Numbers
            </h1>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="border border-[#8b8986] w-full md:w-[30vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-3xl md:text-5xl text-center font-gilroyBold px-2">
                    30%
                  </h1>
                  <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                    increase in customer engagement
                  </h1>
                </div>
                <div className="border border-[#8b8986] w-full md:w-[30vh] py-10 px-5 space-y-8">
                  <h1 className="text-[#303030] text-3xl md:text-5xl text-center font-gilroyBold px-2">
                    50k
                  </h1>
                  <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                    optimized daily active users
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center pt-12 space-y-4 md:space-y-16">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Tools
            </h1>
            <div className="flex flex-col space-y-10">
              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                  Design
                </h1>
                <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6">
                  <LazyImage
                    alt="figma"
                    src="/images/figma.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="ai"
                    src="/images/ai.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="ae"
                    src="/images/ae.png"
                    className="w-full h-auto "
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                  Development
                </h1>
                <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6">
                  <LazyImage
                    alt="react"
                    src="/images/react.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="js"
                    src="/images/js.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="stripe"
                    src="/images/stripe.png"
                    className="w-full h-auto "
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center space-y-6">
                <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                  Marketing & Launch
                </h1>
                <div className="flex flex-col items-center justify-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6">
                  <LazyImage
                    alt="monkey"
                    src="/images/monkey.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="bars"
                    src="/images/bars.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="insta"
                    src="/images/insta.png"
                    className="w-full h-auto "
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center w-full pt-12 space-y-16 md:space-y-24">
            <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
              Process
            </h1>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                1. Discovery & Research
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                We started with in-depth discovery sessions, interviewing the
                client to understand the needs of players, coaches, and parents.
                A detailed competitor and market analysis provided insights into
                existing solutions, helping us identify gaps and opportunities
                to shape Athlantix’s unique value proposition.
              </h1>

              <div className="flex flex-col w-full pt-6 space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                <LazyImage
                  alt="athlantix"
                  src="/images/athlantix/athlantix-2.png"
                  className="w-full h-auto"
                />
                <LazyImage
                  alt="athlantix"
                  src="/images/athlantix/athlantix-3.png"
                  className="w-full h-auto"
                />
              </div>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                2. Brand Design{" "}
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                With a clear vision, we created the visual identity for
                Athlantix, including the logo, typography, color palette, and
                iconography. We documented these elements into a comprehensive
                design system to ensure brand consistency across all
                touchpoints, from the platform interface to social media.
              </h1>

              <LazyImage
                alt="athlantix"
                src="/images/athlantix/athlantix-4.png"
                className="w-full h-auto"
              />

              <div className="flex flex-col w-full space-y-8">
                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-6.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-5.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-7.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-8.png"
                    className="w-full h-auto"
                  />
                </div>
              </div>
              <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                <div className="flex flex-col w-full space-y-8">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-9.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-10.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-11.png"
                    className="w-full h-auto"
                  />
                </div>
              </div>

              <div className="w-full">
                <LazyImage
                  alt="athlantix"
                  src="/images/athlantix/athlantix-12.png"
                  className="w-full h-auto"
                />
              </div>
              <div className="w-full">
                <LazyImage
                  alt="athlantix"
                  src="/images/athlantix/athlantix-13.png"
                  className="w-full h-auto"
                />
              </div>

              <div className="w-full">
                <LazyImage
                  alt="athlantix"
                  src="/images/athlantix/athlantix-14.png"
                  className="w-full h-auto"
                />
              </div>
              <div className="w-full">
                <LazyImage
                  alt="athlantix"
                  src="/images/athlantix/athlantix-15.png"
                  className="w-full h-auto"
                />
              </div>
              <div className="w-full">
                <LazyImage
                  alt="athlantix"
                  src="/images/athlantix/athlantix-16.png"
                  className="w-full h-auto"
                />
              </div>
              <div className="w-full">
                <LazyImage
                  alt="athlantix"
                  src="/images/athlantix/athlantix-17.png"
                  className="w-full h-auto"
                />
              </div>
              <div className="w-full">
                <LazyImage
                  alt="athlantix"
                  src="/images/athlantix/athlantix-18.png"
                  className="w-full h-auto"
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                3. UI/UX Design & Prototyping{" "}
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Our team developed intuitive wireframes and high-fidelity
                mockups for the dashboards, focusing on seamless experiences
                tailored to the needs of players, coaches, and parents.
                Prototypes were tested with select focus groups to gather
                feedback and make necessary improvements before development.
              </h1>
              <div className="flex flex-col w-full space-y-8">
                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-19.png"
                    className="w-full h-auto"
                  />
                </div>
                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-20.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-21.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-22.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-23.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-24.png"
                    className="w-full h-auto"
                  />
                </div>
                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-25.png"
                    className="w-full h-auto"
                  />
                </div>

                {/* //leaving space */}

                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-26.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-27.png"
                    className="w-full h-auto "
                  />
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-28.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-29.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-30.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-31.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-32.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <div className="flex flex-col w-full space-y-8">
                    <LazyImage
                      alt="athlantix"
                      src="/images/athlantix/athlantix-33.png"
                      className="w-full h-auto"
                    />
                  </div>

                  <div className="flex flex-col w-full space-y-12">
                    <LazyImage
                      alt="athlantix"
                      src="/images/athlantix/athlantix-34.png"
                      className="w-full h-auto"
                    />
                    <LazyImage
                      alt="athlantix"
                      src="/images/athlantix/athlantix-35.png"
                      className="w-full h-auto"
                    />
                  </div>
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-36.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-37.png"
                    className="w-full h-auto"
                  />
                </div>
                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-38.png"
                    className="w-full h-auto"
                  />
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-39.png"
                    className="w-full h-auto"
                  />
                </div>

                <div className="w-full">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-40.png"
                    className="w-full h-auto"
                  />
                </div>
                <div className="flex flex-col w-full space-y-8 md:flex-row md:space-y-0 md:space-x-8">
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-41.png"
                    className="w-full h-auto md:w-[30%]"
                  />
                  <LazyImage
                    alt="athlantix"
                    src="/images/athlantix/athlantix-42.png"
                    className="w-full h-auto md:w-[70%]"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                4. Development & Integration
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                The frontend was built using React.js for dynamic interfaces,
                with Node.js handling the backend for efficient data processing.
                We implemented a scalable database with MongoDB and ensured
                real-time data synchronization with Firebase, creating smooth
                user experiences across web and mobile platforms.
              </h1>
            </div>

            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                5. Launch & Marketing
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Athlantix was launched with a strategic marketing campaign
                across social media channels, leveraging content tailored to
                engage athletes, coaches, and parents. Influencer collaborations
                helped boost awareness, and the platform was made available via
                web and mobile stores.
              </h1>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                6. Post-Launch Growth Support
              </h1>
              <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                Following the launch, we provided continuous support by
                monitoring analytics, gathering user feedback, and releasing
                regular updates. This ensured that the platform evolved based on
                real user needs, driving growth and long-term engagement.
              </h1>
            </div>
            <div className="flex flex-col items-center justify-center w-full space-y-8">
              <div className="flex flex-col items-center justify-center pt-16 space-y-8 md:space-y-16">
                <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
                  Key Impact Numbers
                </h1>
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                    <div className="border border-[#8b8986] w-full sm:w-[30vh] py-10 px-5 space-y-8">
                      <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-gilroyBold px-2">
                        30%
                      </h1>
                      <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                        increase in customer engagement
                      </h1>
                    </div>
                    <div className="border border-[#8b8986] w-full sm:w-[30vh] py-10 px-5 space-y-8">
                      <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-gilroyBold px-2">
                        50k
                      </h1>
                      <h1 className="text-[#303030] text-lg sm:text-xl md:text-2xl text-center font-semibold">
                        optimized daily active users
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center w-full pt-20 space-y-8">
                <h1 className="text-[#303030] text-3xl sm:text-4xl md:text-5xl text-center font-semibold">
                  Athlantix Founder’s Review
                </h1>
                <h1 className="text-[#303030] text-base sm:text-lg md:text-xl text-center font-semibold w-full max-w-[90%] sm:max-w-[75%] md:max-w-[55vh]">
                  The team behind Athlantix perfectly captured our vision. They
                  not only built a platform that provides real-time stats and
                  insights but also crafted a strong brand identity and a
                  seamless user experience for players, coaches, and parents.
                  Their post-launch support has been invaluable, and we've seen
                  a clear increase in engagement and user satisfaction.
                </h1>
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="bg-[#1a1a1a] flex flex-col space-y-16 md:space-y-28 pb-12 pt-12 md:pt-24 mt-32 md:mt-64 px-8 md:px-16 lg:px-32 xl:px-48">
          <div className="flex flex-col justify-between space-y-12 md:flex-row md:space-y-0">
            <div className="flex flex-col space-y-6">
              <h1 className="text-[#e6e2dd] text-lg md:text-2xl font-semibold">
                AXILLIO
              </h1>
              <div className="flex flex-col">
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  San Francisco
                </h1>
                <h1 className="text-sm md:text-md text-[#d6d2cd]">
                  Delaware UAE
                </h1>
              </div>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">
                contact@axillio.com
              </h1>
            </div>
            <div className="flex flex-col space-y-4">
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Portfolio</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">Services</h1>
              <h1 className="text-sm md:text-md text-[#d6d2cd]">About</h1>
            </div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-row items-center space-x-1 hover:cursor-pointer">
                <h1 className="text-[#e6e2dd] text-lg font-semibold">
                  Start a Project
                </h1>
                <GoArrowRight className="text-[#e6e2dd] text-lg md:text-xl" />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between space-y-8 md:space-y-12">
            <h1 className="text-sm md:text-md text-[#d6d2cd]">Follow us on:</h1>
            <div className="flex flex-row items-center justify-center space-x-4 md:space-x-8">
              <a
                href="https://www.behance.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="behance"
                  src="./images/behance_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://x.com/AliMukhtar081"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="x"
                  src="./images/x_logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://dribbble.com/axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="football"
                  src="./images/footbal-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/axillio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="linkedin"
                  src="./images/linkedin_symbol.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.instagram.com/axillioofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="insta"
                  src="./images/insta-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
              <a
                href="https://www.youtube.com/@Axillio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyImage
                  alt="youtube"
                  src="./images/youtube-logo.png"
                  className="w-8 h-auto md:w-12 lg:w-full"
                />
              </a>
            </div>

            <h1 className="text-sm md:text-md text-[#d6d2cd]">
              © 2024 Axillio. All Rights Reserved
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Athlantix;
